<template>
	<!-- :style="helloStyle" -->
	<div
		class="hello"
		element-loading-text="拼命转换中..."
		element-loading-spinner="el-icon-loading"
		element-loading-background="rgba(0, 0, 0, 0.8)"
	>
		<router-view />
		<el-dialog
			title="版权说明"
			width="62%"
			custom-class="copyright-block"
			v-model="copyrightVisible"
		>
			<p>请转换合法链接！请勿转换色情/赌博/毒品等非法链接链接！</p>
			<p>不得将该网站用于非法用途，否则，一切后果请用户自负。</p>
			<p>本站不对生成的短链接承担任何法律责任，请合理使用。</p>
			<p>
				如有侵权请邮件与我们联系处理。<a href="mailto:beatree@qq.com"
					>点击发送邮件</a
				>
			</p>
		</el-dialog>

		<footer
			style="position: fixed; width: 100%; bottom: 12px; text-align: center"
		>
			<a @click="copyrightVisible = true">版权声明</a> | 个人博客：<a
				href="https://beatree.cn"
				target="_blank"
				>做棵大树</a
			>
			|
			欢迎扫描右侧二维码关注公众号，第一时间获取更多内容/资源（扫了觉得不好就取关嘛😉）
			<img
				src="@/assets/img/QRCode_marryStyle.png"
				alt="扫码关注"
				srcset=""
				style="width: 120px; position: fixed; bottom: -9px; right: 0px"
			/>
		</footer>
	</div>
</template>

<script>
export default {
	name: "IndexPage",
	data() {
		return {
			helloStyle: {
				"margin-top": "15%",
			},

			loading: false, // 加载中
			detailDialogVisible: false, // 弹窗可见
			downloadInfoVisible: false, // 下载信息
			copyrightVisible: false, // 版权信息
		};
	},
	props: {
		msg: String,
	},

	methods: {},

	mounted() {
		document.onkeydown = () => {
			//禁用F12
			if (window.event && window.event.keyCode == 123) {
				return false;
				//禁用ctrl+shift+i,
			} else if (
				window.event.ctrlKey &&
				window.event.shiftKey &&
				window.event.keyCode == 73
			) {
				return false;
				//屏蔽Shift+F10
			} else if (window.event.shiftKey && window.event.keyCode == 121) {
				return false;
			} else if (
				window.event.ctrlKey &&
				window.event.shiftKey &&
				window.event.keyCode == 67
			) {
				return false;
			}
		};
		// 打开控制台就刷新页面
		var threshold = 100;
		this.timer1 = setInterval(function () {
			//当浏览器窗口宽度或高度与页面的宽度和高度的差值大于100的时候触发页面刷新
			if (
				window.outerWidth - window.innerWidth > threshold ||
				window.outerHeight - window.innerHeight > threshold
			) {
				// 刷新页面；
				window.location.reload();
				alert("请关闭控制台重新输入地址进入网站");
			}
		}, 2000);
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.grid-content {
}
.button {
	padding: 0;
	float: right;
}

// 修复弹窗闪烁
/deep/ .dialog-fade-enter-active,
/deep/ .dialog-fade-enter-to {
	transition: all ease 0.3s !important;
}

/deep/ .el-collapse-item__header {
	font-size: 15px !important;
}

.el-dialog {
	border-radius: 15px;
}

.image {
	width: 100%;
	display: block;
}

.clearfix:before,
.clearfix:after {
	display: table;
	content: "";
}

.clearfix:after {
	clear: both;
}

.shortenUrl-input:focus {
	border: none;
}

// 下载信息div
.downloadInfo {
	text-align: left;
}
h3 {
	margin: 40px 0 0;
}
ul {
	list-style-type: none;
	padding: 0;
}
li {
	display: inline-block;
	margin: 0 10px;
}
a {
	color: #42b983;
}

p {
	font-size: 14px;
}
.copyright-block {
	border-radius: 16px !important;
	margin-top: 15%;
}
</style>
