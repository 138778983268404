import { createRouter, createWebHistory } from 'vue-router'

import IndexPage from '../pages/Index.vue'
import JumpTo from '../components/Jump.vue'
import ShortenUrl from '../components/Shorten.vue'

const routes = [
  {
    path: "/",
    name: "Index",
    component: IndexPage,
    redirect: '/shorten',
    meta: {
      title: '大树短链v1.0 | 做棵大树工具类子站点',
      content: {
        keywords: [
          "短链接",
          "长链接",
          "跳转",
          "URL",
          "Link"],
        description: '将长链接转化为短链接，更便捷，更易分享',
      },

    },
    children: [
      {
        name: 'Shorten', path: 'shorten', component: ShortenUrl
      },
    ]
  },
  { name: "Jump", path: "/t/:surl", component: JumpTo },

  {
    path: "/:catchAll(.*)", // 不识别的path自动匹配404
    redirect: '/',
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
