<template>
	<div :style="state.containerStyle" v-loading.fullscreen.lock="loading">
		<el-row :gutter="24" type="flex">
			<el-col :md="6" hidden-xs-only><div class="grid-content"></div></el-col>
			<el-col :md="12" :xs="24">
				<div class="wrapper">
					<h1>短链接转换</h1>
					<div style="height: 52px">
						<input
							type="text"
							v-model="lurl"
							class="shortenUrl-input"
							placeholder="请输入要缩短的网址链接"
							:style="shortenUrlInputStyle"
							@keyup.enter="shortenUrl()"
						/>
						<el-button
							type="primary"
							style="box-shadow: 0px 0px 2px #4e6ef2"
							@click="shortenUrl()"
						>
							点击缩短
						</el-button>
					</div>
				</div>
			</el-col>
			<el-col :md="6" hidden-xs-only><div class="grid-content"></div></el-col>
		</el-row>

		<el-row :gutter="24" v-if="contentVisible">
			<el-col :md="6" hidden-xs-only><div class="grid-content"></div></el-col>
			<el-col :md="12" :xs="24">
				<textarea style="opacity: 0; height: 0" id="surl"></textarea>
				<p style="font-size: 16px">
					生成的短链接为：
					<Link style="width: 1em; height: 1em; margin-right: 5px" />
					<span style="color: #109dc1; margin-right: 6px">
						{{ surl }}
					</span>
					<CopyButton :content="surl" size="small" />
				</p>
				<!-- 二维码 -->
				<QRCodeVue3
					:width="200"
					:height="200"
					:value="surl"
					:qrOptions="{
						typeNumber: 0,
						mode: 'Byte',
						errorCorrectionLevel: 'Q',
					}"
					:imageOptions="{
						hideBackgroundDots: true,
						imageSize: 0.4,
						margin: 0,
					}"
					:dotsOptions="{
						type: 'rounded',
						color: '#1a2e6b',
						gradient: {
							type: 'linear',
							rotation: 0,
							colorStops: [
								{ offset: 0, color: '#089fc1' },
								{ offset: 1, color: '#4891e3' },
							],
						},
					}"
					:backgroundOptions="{ color: '#ffffff' }"
					:cornersSquareOptions="{ type: 'extra-rounded', color: '#079ec2' }"
					:cornersDotOptions="{ type: 'dot', color: '#7dddb7' }"
					fileExt="png"
				>
				</QRCodeVue3>
				
				可保存上方二维码用于分享
			</el-col>
			<el-col :md="6" hidden-xs-only><div class="grid-content"></div></el-col>
		</el-row>
	</div>
</template>

<script>
import CopyButton from "./CopyButton.vue";
import QRCodeVue3 from "qrcode-vue3";
import { reactive } from "vue";
import { Link } from '@element-plus/icons-vue';

export default {
	name: "ShortenUrl",
	components: { CopyButton, QRCodeVue3, Link},
	setup() {
		const state = reactive({
			containerStyle: {
				"margin-top": "15%",
				transition: "all 0.5s ease",
			},
		});
		return {
			state, // 导出响应式数组
		};
	},
	data() {
		return {
			shortenUrlInputStyle: {
				width: "65%",
				"margin-right": "8px",
				"border-radius": "8px",
				"box-shadow": "0px 0px 2px #4e6ef2",
				border: "0px",
				height: "36px",
				"box-sizing": "border-box",
				padding: "12px",
				"font-size": "16px",
				outline: "none",
				"vertical-align": "middle",
			},

			lurl: "", // 长链接
			surl: "https://beatree.cn", // 短链接
			idempotentId: "", // 幂等控制
			contentVisible: false, // 内容可见
			loading: false,
		};
	},
	methods: {
		shortenUrl() {
			if (this.idempotentId == this.lurl) {
				var message = "耐心哦~ 不妨关注下公众号：“做棵大树” ？";
				if (this.loading == false) {
					message = "建议老板换个链接重试下~";
				}
				this.$message({
					type: "warning",
					message: message,
				});
				return;
			}
			// 更新上次检索
			this.idempotentId = this.lurl;

			this.loading = true;
			let lurl = this.lurl;
			this.axios
				.post("url/shorten", { lurl: lurl })
				.then((response) => {
					let res = response.data;
					console.log(res);
					let message = res.msg;
					let infoType = "warning";

					this.loading = false;
					// 返回值正确
					if (res.code == 200) {
						infoType = "success";
						this.surl = res.data;
						this.contentVisible = true;
						// 更新CSS样式
						this.updateCss();
					}

					this.$message({
						type: infoType,
						message: message,
					});
				})
				.catch((error) => {
					this.loading = false;
					this.$message({
						type: "error",
						message: error,
					});
					this.idempotentId = ""; // 出错时，可重试
				});
		},
		// 更新CSS样式为显示搜索结果的样式
		updateCss() {
			this.state.containerStyle["margin-top"] = "5%";
		},
	},
};
</script>

<style lang="scss" scoped></style>
