import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import store from './store/index.js'
import router from './router/index.js'
import axios from 'axios';
// import '@/assets/style/transition.css'
// import '@/assets/style/theme/index.css'
import Clipboard from 'v-clipboard3';


const app = createApp(App).use(router)

app.use(store)
app.use(router)
app.use(ElementPlus)
app.use(Clipboard);


// axios 配置
axios.defaults.crossDomain = true;
// axios.defaults.withCredentials = true;
//此处是增加的代码，设置请求头的类型
axios.defaults.headers['Content-Type'] = 'application/json;charset=UTF-8'
// 禁用http请求缓存from disk cache
axios.defaults.headers['Cache-Control'] = 'no-cache'
axios.defaults.withCredentials = true;  //设置cross跨域 并设置访问权限 允许跨域携带cookie信息
axios.interceptors.response.use(function (response) {
  console.log("axios.interceptors.response", response);
  var res = response.data;
  if (res.code == 403) {
    console.log("403 forbidden ", res)
    localStorage.removeItem("currentUser");
    localStorage.removeItem("userInfo");
    // 清除用户信息
    store.commit("clearUserInfo");
    router.push("/index");
    Element.$message({
      message: 'token失效',
      type: 'danger',
    })
  }

  return response;
}, function (error) {
  // 对响应错误做点什么
  return Promise.reject(error);
});

app.config.globalProperties.axios = axios;

app.mount('#app')
