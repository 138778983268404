<template>
	<!-- <el-result title="跳转提示" :subTitle="'即将跳转至：' + lurl + '，请评估网站安全性'">
    <template slot="icon">
      <el-image src="https://shadow.elemecdn.com/app/element/hamburger.9cf7b091-55e9-11e9-a976-7f4d0b07eef6.png"></el-image>
    </template>
    <template slot="extra">
      <el-button type="primary" size="medium">返回</el-button>
    </template>
  </el-result> -->
	<div
		v-loading.fullscreen.lock="loading"
		element-loading-text="努力跳转中..."
		element-loading-spinner="el-icon-loading"
		element-loading-background="rgba(0, 0, 0, 0.8)"
	>
		<el-row>
			<el-result
				v-if="errorVisible"
				icon="warning"
				title="错误提示"
				subTitle="短链接已失效或被屏蔽，如有需要请联系管理员"
			>
				<template #extra> </template>
			</el-result>
		</el-row>
		<!-- 短链接站点使用 -->

		<!-- Google 广告 -->
		<div v-html="googleAds"></div>
		<!-- Google 广告 -->
	</div>
</template>

<script>
export default {
	name: "JumpTo",
	data() {
		return {
			loading: true, // 加载状态
			errorVisible: false, // 错误信息
			surl: "", // 短连接参数
			lurl: "", // 长链接参数
			googleAds: `<!-- 短链接站点使用 -->
			 <ins class="adsbygoogle"
				style="display:block"
				data-ad-client="ca-pub-7551570018019915"
				data-ad-slot="4326174466"
				data-ad-format="auto"
				data-full-width-responsive="true"></ins>`,
		};
	},
	methods: {
		// 还原为长链接
		async restoreUrl() {
			var isSuccess = true;
			await this.axios
				.get("url/restore/" + this.surl)
				.then((response) => {
					let res = response.data;
					let message = res.msg;

					if (res.code != 200) {
						this.$message({
							type: "warning",
							message: message,
						});
						isSuccess = false;
					}

					this.lurl = res.data;
				})
				.catch((error) => {
					this.loading = false;
					this.errorVisible = true;

					this.$message({
						type: "error",
						message: error,
					});
				});

			return isSuccess;
		},

		getSurl() {
			this.surl = this.$route.params.surl;
		},

		jump() {
			this.getSurl();
			this.restoreUrl().then((isSuccess) => {
				console.log(this.lurl);
				if (isSuccess) {
					setTimeout(() => {
						window.location.replace(this.lurl);
					}, 1000);
				} else {
					this.loading = false;
					this.errorVisible = true;
				}
			});
		},
	},
	mounted() {
		this.jump();
	},
};
</script>

<style lang="scss" scoped></style>
