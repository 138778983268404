<template>
	<el-button
		v-clipboard:copy="content"
		v-clipboard:success="clipboardSuccessHandler"
		v-clipboard:error="clipboardErrorHandler"
		:type="successCopy ? 'success' : 'primary'"
		:size="size"
	>
		<span>点击复制</span>
		<el-icon  v-if="successCopy" class="el-icon--right"><CircleCheckFilled /></el-icon>
	</el-button>
</template>

<script>
import { CircleCheckFilled } from '@element-plus/icons-vue';
export default {
  name: "CopyButton",
	components: {
		CircleCheckFilled
	},
  props: {
    content: {
      type: String,
      default: "https://beatree.cn"
    },
    size: {
      type: String,
      default: "small"
    }
  },
  data(){
    return {
      successCopy: false,
    }
  },

  methods: {
    getContent(){
      return this.content;
    },
    // 拷贝链接
		clipboardSuccessHandler({ value, event }) {
			console.log(value, event);
			this.$message({
				message: "复制成功",
				type: "success",
			});
			this.successCopy = true;
		},
		clipboardErrorHandler({ value, event }) {
			console.log(value, event);
			this.$message({
				message: value,
				type: "error",
			});
			this.successCopy = false;
		},
  }
};
</script>

<style lang="scss" scoped></style>
